import { FiClock, FiPause, FiPlay } from "react-icons/fi";
import dayjs from "dayjs";
import useApp from "hooks/useApp";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import "moment-timezone";
import { IoReloadOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const times = [
  { label: "12:00 AM", value: { h: "00", m: "00" } },
  { label: "12:30 AM", value: { h: "00", m: "30" } },
  { label: "01:00 AM", value: { h: "01", m: "00" } },
  { label: "01:30 AM", value: { h: "01", m: "30" } },
  { label: "02:00 AM", value: { h: "02", m: "00" } },
  { label: "02:30 AM", value: { h: "02", m: "30" } },
  { label: "03:00 AM", value: { h: "03", m: "00" } },
  { label: "03:30 AM", value: { h: "03", m: "30" } },
  { label: "04:00 AM", value: { h: "04", m: "00" } },
  { label: "04:30 AM", value: { h: "04", m: "30" } },
  { label: "05:00 AM", value: { h: "05", m: "00" } },
  { label: "05:30 AM", value: { h: "05", m: "30" } },
  { label: "06:00 AM", value: { h: "06", m: "00" } },
  { label: "06:30 AM", value: { h: "06", m: "30" } },
  { label: "07:00 AM", value: { h: "07", m: "00" } },
  { label: "07:30 AM", value: { h: "07", m: "30" } },
  { label: "08:00 AM", value: { h: "08", m: "00" } },
  { label: "08:30 AM", value: { h: "08", m: "30" } },
  { label: "09:00 AM", value: { h: "09", m: "00" } },
  { label: "09:30 AM", value: { h: "09", m: "30" } },
  { label: "10:00 AM", value: { h: "10", m: "00" } },
  { label: "10:30 AM", value: { h: "10", m: "30" } },
  { label: "11:00 AM", value: { h: "11", m: "00" } },
  { label: "11:30 AM", value: { h: "11", m: "30" } },
  { label: "12:00 PM", value: { h: "12", m: "00" } },
  { label: "12:30 PM", value: { h: "12", m: "30" } },
  { label: "01:00 PM", value: { h: "13", m: "00" } },
  { label: "01:30 PM", value: { h: "13", m: "30" } },
  { label: "02:00 PM", value: { h: "14", m: "00" } },
  { label: "02:30 PM", value: { h: "14", m: "30" } },
  { label: "03:00 PM", value: { h: "15", m: "00" } },
  { label: "03:30 PM", value: { h: "15", m: "30" } },
  { label: "04:00 PM", value: { h: "16", m: "00" } },
  { label: "04:30 PM", value: { h: "16", m: "30" } },
  { label: "05:00 PM", value: { h: "17", m: "00" } },
  { label: "05:30 PM", value: { h: "17", m: "30" } },
  { label: "06:00 PM", value: { h: "18", m: "00" } },
  { label: "06:30 PM", value: { h: "18", m: "30" } },
  { label: "07:00 PM", value: { h: "19", m: "00" } },
  { label: "07:30 PM", value: { h: "19", m: "30" } },
  { label: "08:00 PM", value: { h: "20", m: "00" } },
  { label: "08:30 PM", value: { h: "20", m: "30" } },
  { label: "09:00 PM", value: { h: "21", m: "00" } },
  { label: "09:30 PM", value: { h: "21", m: "30" } },
  { label: "10:00 PM", value: { h: "22", m: "00" } },
  { label: "10:30 PM", value: { h: "22", m: "30" } },
  { label: "11:00 PM", value: { h: "23", m: "00" } },
  { label: "11:30 PM", value: { h: "23", m: "30" } },
];

import { useState, useEffect } from "react";
import classNames from "utilities/ClassNames";
// import useData from "hooks/useData";
import moment from "moment-timezone";

export default function DateTime() {
  const { user, isScaled } = useApp();
  // const { pastDateHandler, setDayUnity } = useData();
  const { dateTime, setDateTime } = useApp();
  const [day, setDaySkyBox] = useState(1);
  const defaultTimeZone = "Asia/Kuwait";
  const userTimeZone = user?.ianaTimeZone || defaultTimeZone;

  const excludeStartDate = new Date(
    moment().set("year", 2022).set("month", 4).set("date", 25).format("lll")
  );
  const excludeEndDate = new Date(
    moment().set({ year: 2022, month: 11, date: 5 }).format("lll")
  );
  const formatter = "YYYY-MM-DD[T]HH:mm:ss";

  const timeZoneTime = () => {
    const localTime = new Date();
    const momentTime = moment(localTime).tz(userTimeZone);

    return new Date(momentTime.format(formatter));
  };

  const [timer, runTimer] = useState(true);
  const [selectedDate, setSelectedDate] = useState(timeZoneTime());
  const [calendar, showCalendar] = useState(false);
  const [isCurrentDate, setIsCurrentDate] = useState(true);
  const [paused, setPaused] = useState(false);

  // useEffect(() => {
  //   if (paused) {
  //     pastDateHandler(true, moment(selectedDate).format(formatter));
  //   }
  // }, [paused, pastDateHandler, selectedDate]);

  useEffect(() => {
    if (calendar) {
      runTimer(false);
    } else {
      runTimer(true);
      if (!isCurrentDate) {
        setPaused(false);
      }
    }
  }, [calendar, isCurrentDate]);

  useEffect(() => {
    const checkSaudiDayNight = (dateTime: Date) => {
      const timeSaudi = moment
        .tz(moment(dateTime).format(formatter), userTimeZone)
        .tz("Asia/Kuwait");
      const hour = timeSaudi.hours();
      if (hour > 5 && hour < 18) {
        if (!day) {
          setDaySkyBox(1);
        }
      } else if (day) {
        setDaySkyBox(0);
      }
    };

    checkSaudiDayNight(selectedDate);
  }, [selectedDate, day, userTimeZone]);

  useEffect(() => {
    const secondsTimer = setInterval(() => {
      setSelectedDate((state) => {
        let newDate;
        if (isCurrentDate) {
          newDate = timeZoneTime();
        } else {
          newDate = state;
          const x = newDate.getSeconds() + 1;
          newDate.setSeconds(x);
        }

        return new Date(newDate);
      });
    }, 1000);

    if (!timer || paused) {
      clearInterval(secondsTimer);
    }
    return () => {
      clearInterval(secondsTimer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
    // eslint-disable-next-line
  }, [timer, paused, isCurrentDate, userTimeZone]);

  // useEffect(() => {
  //   setDayUnity(day);
  // }, [day, setDayUnity]);

  /* Starts === Updating past data for every 1 minute  - if the timer running for the past date */
  // useEffect(() => {
  //   const SyncPastDataWithTimer = setInterval(() => {
  //     setSelectedDate((state) => {
  //       pastDateHandler(true, moment(new Date(state)).format(formatter));
  //       return state;
  //     });
  //   }, 60 * 1000);

  //   if (isCurrentDate || (!isCurrentDate && paused)) {
  //     clearInterval(SyncPastDataWithTimer);
  //   }
  //   return () => {
  //     clearInterval(SyncPastDataWithTimer);
  //   };
  //   // eslint-disable-next-line
  // }, [paused, isCurrentDate]);
  /* Ends === Updating past data for every 1 minute  - if the timer running for the past date */

  const clockIconHandler = () => {
    showCalendar((state) => !state);
  };

  const calendarContainer = ({ children }: any) => {
    return (
      <div className="calendar">
        <div className="sidebar-card">
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  const handleCalendarClose = () => {};

  const handleCalendarOpen = () => {
    showCalendar(true);
  };

  const dateTimeHandler = (date: Date) => {
    setSelectedDate(date);
    // pastDateHandler(true, moment(date).format(formatter));
    setIsCurrentDate(false);
  };

  const playPauseHandler = () => {
    setPaused((state) => !state);
  };

  const refreshHandler = () => {
    setSelectedDate(timeZoneTime());
    setIsCurrentDate(true);
    setPaused(false);
    runTimer(true);
    // pastDateHandler(false, null);
  };

  const disablePauseBtn = paused ? "play-pause-disable" : "";
  const disablePlayBtn = !paused ? "play-pause-disable" : "";

  return (
    <div
      className="date-time-container date-time flex justify-center items-center mr-5"
      style={{ position: "relative" }}
    >
      {calendar && (
        <div
          style={{
            position: "fixed",
            zIndex: "10",
            inset: 0,
            width: "100%",
            height: "100%",
          }}
          onClick={() => showCalendar(false)}
        />
      )}
      <div
        className="card-content flex items-center"
        style={{ position: "relative", zIndex: "20" }}
      >
        {!isCurrentDate && (
          <div
            className="hover:text-green cursor-pointer mr-3"
            onClick={refreshHandler}
          >
            <IoReloadOutline size={isScaled ? 25 : 22} />
          </div>
        )}

        {/* <div className="flex items-center mr-3 gap-2">
          <div
            className={`${
              isCurrentDate
                ? "text-lightGray cursor-none"
                : "text-green cursor-pointer"
            }`}
          >
            <div onClick={playPauseHandler}>
              <FiPlay size={20} />
            </div>
          </div>
          <div
            className={`${
              isCurrentDate
                ? "text-lightGray cursor-none"
                : "text-green cursor-pointer"
            }`}
          >
            <div onClick={playPauseHandler}>
              <FiPause size={20} />
            </div>
          </div>
        </div> */}

        <DatePicker
          selected={selectedDate}
          onInputClick={handleCalendarOpen}
          onChange={dateTimeHandler}
          showTimeSelect={true}
          timeIntervals={30}
          className={classNames(
            isScaled ? "w-[300px] text-xl" : "w-[225px] text-base",
            "mr-3 border-none outline-none bg-dark cursor-pointer"
          )}
          dateFormat="MMMM d, yyyy h:mm:ss aa"
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          maxDate={timeZoneTime()}
          calendarContainer={calendarContainer}
          startOpen={false}
          timeInputLabel="Time:"
          includeDateIntervals={[
            { start: new Date("May 5, 2021"), end: timeZoneTime() },
          ]}
          excludeDateIntervals={[
            { start: excludeStartDate, end: excludeEndDate },
          ]}
          open={calendar}
          showDisabledMonthNavigation
        />

        <div className="hover:text-green cursor-pointer">
          <FiClock size={isScaled ? 25 : 22} onClick={clockIconHandler} />
        </div>
      </div>
    </div>
    // <div className="relative mr-5 flex items-center gap-7">
    //   <div className="flex gap-3 items-center">
    //     <div className="cursor-pointer hover:text-green">
    //       <FiPlay />
    //     </div>
    //     <div className="cursor-pointer hover:text-green">
    //       <FiPause />
    //     </div>
    //   </div>
    //   {open && (
    //     <div
    //       className="fixed z-10 inset-0 w-full h-full bg-transparent"
    //       onClick={() => setOpen(false)}
    //     />
    //   )}
    //   <div className="relative z-20">
    //     <Popover open={open}>
    //       <PopoverTrigger
    //         asChild
    //         className="text-white cursor-pointer"
    //         onClick={() => setOpen(true)}
    //       >
    //         <div className="flex items-center gap-2">
    //           <div>
    //             {dayjs(date).format("MMMM DD,YYYY")} {clock}
    //           </div>
    //           <span>
    //             <FiClock />
    //           </span>
    //         </div>
    //       </PopoverTrigger>
    //       <PopoverContent className="w-auto p-0 bg-black mt-5" align="start">
    //         <div className="flex gap-3">
    //           <Calendar
    //             mode="single"
    //             selected={date}
    //             onSelect={setDate}
    //             initialFocus
    //           />
    //           <div>
    //             <div className="p-4 h-72 w-28 rounded-md border my-2 mr-2 overflow-hidden overflow-y-scroll">
    //               <h4 className="mb-4 text-sm font-medium leading-none">
    //                 Time
    //               </h4>
    //               {times.map((time) => (
    //                 <div key={time.label}>
    //                   <div
    //                     onClick={() => {
    //                       setSelectedTime(time.value);
    //                       if (time && date) {
    //                         setOpen(false);
    //                       }
    //                     }}
    //                     className={classNames(
    //                       time === selectedTime ? "text-green" : "",
    //                       "text-sm cursor-pointer hover:text-green"
    //                     )}
    //                   >
    //                     {time.label}
    //                   </div>
    //                   <Separator className="my-2 bg-gray" />
    //                 </div>
    //               ))}
    //             </div>
    //           </div>
    //         </div>
    //       </PopoverContent>
    //     </Popover>
    //   </div>
    // </div>
    // <div className="mr-5 rounded-md p-3 hover:bg-black cursor-pointer flex items-center gap-2">
    //   <span>{dayjs(new Date()).format("MMMM DD,YYYY THH:mm:ss A")}</span>
    //   <span className="text-green">
    //     <FiClock />
    //   </span>
    // </div>
  );
}
