import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppProvider } from "hooks/useApp";
import { ContextProvider } from "utilities/ContextProvider";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.log(process.env.REACT_APP_AUTH_APP);

const oidcConfig = {
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
  authority: `${process.env.REACT_APP_AUTH_APP}`,
  redirect_uri: `${process.env.REACT_APP_CULTURAL_APP}`,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
};

root.render(
  <React.StrictMode>
    {/* <AuthProvider {...oidcConfig}> */}
      <AppProvider>
        <ContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ContextProvider>
      </AppProvider>
    {/* </AuthProvider> */}
  </React.StrictMode>
);
