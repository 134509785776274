import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Notes from "./note";
import Note from "./note";
import { getAllNotes } from "api/note";
import useApp from "hooks/useApp";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SplashScreen } from "./SplashScreen";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: 20,
            borderRadius: 10,
            backgroundColor: '#333333',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#47c2fd',
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const UnityWP = () => {
  const [shownote, setshownote] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const { user, isScaled } = useApp();

  const [loadingStatus, setLoadingStatus] = useState(
    "Loading data from the network"
  );

  const {
    unityProvider,
    sendMessage,
    loadingProgression,
    isLoaded,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "Build/Unity/WebGL.loader.js",
    dataUrl: "Build/Unity/WebGL.data.unityweb",
    frameworkUrl: "Build/Unity/WebGL.framework.js.unityweb",
    codeUrl: "Build/Unity/WebGL.wasm.unityweb",
    // streamingAssetsUrl: "Build/Unity/StreamingAssets",
  });

  console.log('loaded ' + loadingProgression);

  useEffect(() => {
    if (isLoaded) {
      setLoadingStatus(
        "We are building your digital twin, it will take from 30 seconds till 4 min depends on graphic card"
      );
    }
  }, [isLoaded]);

  const noteCloseHandler = useCallback(() => {
    setshownote(false);
  }, []);

  const createNote: any = useCallback((x: number, y: number, z: number) => {
    const noteDetails = { x, y, z };
    setNotePopup(noteDetails);
    setNoteId(null);
    setshownote(true);
  }, []);

  const showunity = () => {
    setShowLogo(false);
  };

  const openNote: any = useCallback((noteId: number) => {
    setNotePopup(null);
    setNoteId(noteId);
    setshownote(true);
  }, []);

  useEffect(() => {
    if (!showLogo) {
      console.log("SENDING USER DETAILS");
      sendMessage("UserDetails", "setUserDetails", JSON.stringify(user));
    }
  }, [showLogo]);

  useEffect(() => {
    addEventListener("NewNoteAdded", createNote);
    addEventListener("UnityIsReady", showunity);
    addEventListener("openNote", openNote);
    return () => {
      removeEventListener("NewNoteAdded", createNote);
      removeEventListener("UnityIsReady", showunity);
    };
  }, [addEventListener, removeEventListener, createNote, showunity]);

  const [notePopup, setNotePopup] = useState<any>(null);
  const [popupNoteId, setNoteId] = useState<number | null>(null);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [allNotes, setAllNotes] = useState<any>(null);
  const [unityLoadingProgress, setUnityLoadingProgress] = useState(0);

  const getData = async () => {
    const response = await getAllNotes();
    if (response.status === 200) {
      setAllNotes(response.data);
    }
  };

  useEffect(() => {
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
  }, [allNotes]);

  useEffect(() => {
    if (!showLogo) {
      console.log("GETTING NOTES DATA");
      getData();
    }
  }, [showLogo]);

  function unityLoadingInterval() {
    const intervalId = setInterval(() => {
      setUnityLoadingProgress((prevProgress) => {
        if (prevProgress !== 99) {
          return prevProgress + 1;
        } else {
          clearInterval(intervalId);
          return prevProgress;
        }
      });
    }, 3000);
  }

  useEffect(() => {
    if (unityLoadingProgress < 90) {
      setUnityLoadingProgress(loadingProgression * 100);
    } else {
      setUnityLoadingProgress(90);
      unityLoadingInterval();
    }
  }, [loadingProgression]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (unityLoadingProgress >= 90) {
      intervalId = setInterval(() => {
        setUnityLoadingProgress((prevProgress) => {
          if (prevProgress >= 99) {
            if (intervalId) clearInterval(intervalId);
            return prevProgress;
          }
          return prevProgress + 1;
        });
      }, 3000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [unityLoadingProgress]);

  return (
    // TODO change height values to 100%
    <div className="grow h-[70%] max-h-[99%]">
      {shownote && (
        <Note
          noteId={popupNoteId}
          modalOpen={noteModalOpen}
          noteDetails={notePopup}
          closeHandler={noteCloseHandler}
          getNotes={getData}
        />
      )}
      <div id="unity-container" className={`w-full h-full relative`}>
        {!isLoaded && (
          <SplashScreen
            open={showLogo}
            logo={true}
            status={loadingStatus}
            isScaled={isScaled}
            progressStatus={unityLoadingProgress}
          />
        )}
        <Unity
          unityProvider={unityProvider}
          className="unityApp"
          style={{
            width: "100%",
            height: "100%",
            visibility: "visible",
          }}
          devicePixelRatio={isScaled ? 1.308 : 1}
        />
      </div>
    </div>
  );
};

export default UnityWP;
