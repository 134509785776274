import BrainImg from "components/shared/BrainImg";
import { motion } from "framer-motion";
import useApp from "hooks/useApp";
import { useEffect, useState } from "react";
import { BsTags } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { RxDashboard, RxGlobe, RxIdCard, RxStopwatch } from "react-icons/rx";
import { TbBrandUnity } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import classNames from "utilities/ClassNames";
import { AiOutlineFieldTime } from "react-icons/ai";

export default function SideMenu({ closeMenu, showSideMenu }: any) {
  const [alarmCount, setAlarmCount] = useState();
  const { isScaled, userRole } = useApp();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const links = [
    {
      id: 1,
      name: "Saudi Map",
      to: `${process.env.REACT_APP_COMMON_APP}`,
      icon: <RxGlobe size={isScaled ? 25 : 20} />,
    },
    {
      id: 6,
      name: "Users",
      to: "/users",
      icon: <FiUsers size={isScaled ? 25 : 20} />,
    },
    {
      id: 7,
      name: "Logs",
      to: "/logs",
      icon: <AiOutlineFieldTime size={isScaled ? 30 : 25} />,
    },
  ];

  const slide = {
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
      },
      display: "flex",
    },
    exit: {
      opacity: 0,
      x: "-100%",
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  const appear = {
    enter: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
      display: "block",
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.1,
        delay: 0.3,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  // const getAlarmCount = async () => {
  //   const response: any = await getCount();
  //   if (response.status === 200) {
  //     setAlarmCount(response.data);
  //   }
  // };
  // useEffect(() => {
  //   getAlarmCount();
  // }, []);


  const handleNavLinkClick = (targetPath:any) => {
    if (window.location.pathname === "/water-tower") {
      // Set the state to indicate that a refresh should occur
      setShouldRefresh(true);
    }

    console.log("targetPath",targetPath);

    // Manually update the URL
    window.location.href = targetPath;
  };

  // useEffect to trigger a reload when the state changes
  useEffect(() => {
    if (shouldRefresh) {
      window.location.reload();
      // Reset the state to prevent continuous reloading
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);


  console.log(window.location.pathname);

  return (
    <motion.div
      initial="exit"
      animate={showSideMenu ? "enter" : "exit"}
      variants={appear}
      className="fixed z-40 inset-0 w-full bg-black/90"
      onClick={closeMenu}
    >
      <motion.div
        initial="exit"
        animate={showSideMenu ? "enter" : "exit"}
        variants={slide}
        className={classNames(
          isScaled ? "w-[350px]" : "w-[260px]",
          "absolute h-full left-0 top-0 z-20 bg-gray"
        )}
      >
        <div className="h-full w-full mt-20 pb-20 flex flex-col justify-between">
          <ul className="flex flex-col">
            {userRole === "admin" &&
              links.map((link: any) => (
                <NavLink
                  key={link.id}
                  to={link.to}
                  onClick={()=>handleNavLinkClick(link.to)}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                      : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
                  }
                >
                  {link.icon}
                  <span className={isScaled ? "text-xl" : "text-base"}>
                    {link.name}
                  </span>
                </NavLink>
              ))}
            {userRole !== "admin" &&
              links.map((link: any) => (
                (link.to !=="/logs" && <NavLink
                  key={link.id}
                  to={link.to}
                  onClick={()=>handleNavLinkClick(link.to)}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                      : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
                  }
                >
                  {link.icon}
                  <span className={isScaled ? "text-xl" : "text-base"}>
                    {link.name}
                  </span>
                </NavLink>)
              ))}
          </ul>
          <div className="grid place-content-center">
            <BrainImg />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
