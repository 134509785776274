import logoImg from "assets/logo.svg";
import Loader from "components/shared/Loader";
import classNames from "utilities/ClassNames";
import Box from '@mui/material/Box';
import { SplashScreenInterface } from "./domain/types";
import ProgressBar from "components/shared/ProgressBar/ProgressBar";

export const SplashScreen: React.FunctionComponent<SplashScreenInterface> = ({
  open = false,
  status,
  isScaled,
  progressStatus,
}) => {
  if (open) {
    return (
      <>
        <div className="absolute grid place-content-center h-full w-full bg-black">
          <div className="flex flex-col items-center gap-5">
            <img
              src={logoImg}
              alt="Processhub"
              className={classNames(isScaled ? "logo-large" : "logo-medium")}
            />
            <Loader />
            <div className={classNames(isScaled ? "text-2xl" : "text-base")}>
              {status}
            </div>
            <div className="w-[30vw]">
              <ProgressBar value={progressStatus} />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};
