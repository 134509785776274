import React, { useEffect, useState, useRef } from "react";
import CustomizedTimeline from "./Timeline";
import { Button } from "@/components/ui/button";
import { ILogs } from "./domain/logsProp";
import { getData } from "./domain/getLogsData";
import { downloadPopup } from "./config/downloadPopup";
import { handleUpload } from "./utils/handleUpload";
import useApp from "hooks/useApp";


const Logs: React.FunctionComponent = () => {
  const [data, setdata] = useState<ILogs[]>([]);
  const [showpopup, setshowpopup] = useState(false);
  const { isScaled } = useApp();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;

        if (content.endsWith("ajx")) {
          const json = window.atob(content.slice(0, -3));
          const data = JSON.parse(json);
          setdata(data);
        }
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const logsdata = await getData({});
        setdata(logsdata);
      } catch (error) {
        console.log("error logs: ", error);
      }
    })();
  }, []);

  const fileInputRef: any = useRef(null);

  const ToggleDownloadPopup = () => {
    setshowpopup(() => !showpopup);
  };

  return (
    <>
      <div className="row mt-10">
        <div className="flex mt-9 ml-3">
          <Button className="btn mr-3" onClick={ToggleDownloadPopup}>
            {" "}
            Download
          </Button>
          <Button className="btn mr-3" onClick={handleUpload}>
            {" "}
            Upload
          </Button>
          {showpopup && (
            <div className="absolute bg-black border-2 border-darkcyan rounded-lg w-32 h-16 left-0 top-32 z-50">
              {downloadPopup.map((item: any, index: any) => (
                <div
                  key={index}
                  className="text-base px-2 text-darkcyan cursor-pointer"
                  onClick={() => {
                    item.onclick(data);
                    ToggleDownloadPopup();
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
        {/* <Button className='tags-config-button' type="file" onClick={handleUpload}>Upload</Button> */}
        <input
          ref={fileInputRef}
          type="file"
          accept=".ph"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
      <h1 style={{ textAlign: "center", marginTop: "-45px", fontSize: "35px" }}>
        LOGS
      </h1>
      <div style={{ marginTop: "50px", marginLeft: "10px" }}>
        <CustomizedTimeline logs={data} />
      </div>
    </>
  );
};

export default Logs;
