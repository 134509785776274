import Layout from "components/layout";
// import Auth from "pages/auth";
// import Login from "pages/auth/Login";
// import Register from "pages/auth/Register";
// import ForgetPassword from "pages/auth/ForgetPassword";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthGuard } from "utilities/AuthGuard";
import "./App.css";
import UnityWP from "pages/waterplant";
import Users from "pages/users/UsersContainer";
import Logs from "pages/Logs";

function App() {
  return (
    <Routes>
        <Route element={<Layout />}>
          {/* <Route index element={<Navigate to="/" />} /> */}
          <Route index path="/" element={<UnityWP />}></Route>
          <Route path="/users" element={<Users />}></Route>
          <Route path="/logs" element={<Logs />} />
        </Route>
      {/* </Route> */}
      <Route path="*" element={<p>Not Found</p>} />
    </Routes>
  );
}

export default App;
