import classNames from "utilities/ClassNames";
import { Box } from "@mui/system";
import { getIconForAction } from "./config/IconMapping";
import { iconsColor, iconsSizes } from "./utils/constants";
import useApp from "hooks/useApp";

const LogDetails = (props: any) => {
  const { isScaled } = useApp();

  return (
    <Box width={"100%"}>
      <div
        className={classNames(
          isScaled ? "text-3xl" : "text-2xl",
          "text-center border-b-2 border-[rgb(22,245,211)] py-2"
        )}
      >
        Details
      </div>
      <div
        className="py-1 px-2"
        style={{
          borderBottom: "2px rgb(22,245,211) solid",
        }}
      >
        <div
          className="row"
          style={{
            paddingTop: "7px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {props.LogData.action && (
            <>
              {getIconForAction(props.LogData.action, iconsColor, iconsSizes)}
            </>
          )}
          <div style={{ paddingLeft: "10px", paddingTop: "7px" }}>
            {props.LogData.module}
          </div>
        </div>
        <div className="row" style={{ paddingBottom: "10px" }}>
          <div style={{ flexWrap: "nowrap", flexDirection: "column" }}>
            <div>{props.LogData.at}</div>
          </div>
          <div
            className="col"
            style={{ display: "flex", justifyContent: "right" }}
          >
            {props.LogData.by}
          </div>
        </div>
        <div
          className="col"
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          {props.LogData.desc}
        </div>
      </div>
      <pre
        style={{
          marginTop: "50px",
          lineHeight: "25px",
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
        className="p-2"
      >
        {props.LogData.detail}
      </pre>
    </Box>
  );
};
export default LogDetails;
