import { getSystemLogs } from "api/dashboard";

export const getData = async ({action,module,DaystoFilter}:any) => {
  const durType=3;
  let response: any = null;
    try{
        if(!action && !module && !DaystoFilter){
          response=await getSystemLogs({});
        }
        else if((action || module) && DaystoFilter){
          response = await getSystemLogs({
            action: action,
            module: module,
            count: DaystoFilter,
            durType: durType
          });
        }
        else{
          response = await getSystemLogs({
            action: action,
            module: module,
          });
        }
        if (response.status === 200 && response.data) {
          const tempData = response.data;
          tempData.map((item: any) => {
            const datestring = item.at;
            const date = new Date(datestring);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");
            const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
            const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
            item.at = formattedDate;
          });
          return tempData;
        }
    }
    catch(e){
        console.log("Error fetch");
        throw(e);
    }
  };