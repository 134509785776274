import { getUserInfo } from "api/user";
import Loader from "components/shared/Loader";
import { createContext, useContext, useEffect, useState } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
export const AppContext = createContext<any | null>(null);
const parseJwt = (token: any) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};
export const AppProvider = ({ children }: { children: any }) => {
  const [isLoggedIn, setIsLoggedIn] = useState("loggenId");
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState<any>(null);
  const [role, setrole] = useState<any>(null);
  const [userRole, setuserRole] = useState<any>(null);
  const [dateTime, setDateTime] = useState<any>(null);
  const [isScaled, setIsScaled] = useState<any>(null);
  const auth = useAuth();
  useEffect(() => {
    if (window.devicePixelRatio === 1.25) {
      setIsScaled(true);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("dateTime", dateTime);
  }, [dateTime]);

  // useEffect(() => {
  //   (async () => {
  //     if(auth.isAuthenticated){
  //       const response= await getUserInfo();
  //       if(response.status===200){
  //         setUser(response.data);
  //         setuserRole(response.data.role);
  //       }
  //     }
  //       })();
  // }, [auth]);

    useEffect(() => {
    if (userRole) {
      if (userRole.includes("admin")) {
        setuserRole("admin");
      } else {
        setuserRole("user");
      }
    }
  }, [userRole]);
  
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  // automatically sign-in
  // useEffect(() => {
  //   if (
  //     !hasAuthParams() &&
  //     !auth.isAuthenticated &&
  //     !auth.activeNavigator &&
  //     !auth.isLoading &&
  //     !hasTriedSignin
  //   ) {
  //     auth.signinRedirect();
  //     setHasTriedSignin(true);
  //   }
  // }, [auth, hasTriedSignin]);
  // switch (auth.activeNavigator) {
  //   case "signinSilent":
  //     return <div>Signing you in...</div>;
  //   case "signoutRedirect":
  //     return <div>Signing you out...</div>;
  // }
  // if (auth.error) {
  //   return <div>Oops... {auth.error.message}</div>;
  // }
  // if (auth.isLoading)
  //   return (
  //     <div className="bg-black fixed inset-0 grid place-content-center">
  //       <Loader />
  //     </div>
  //   );
    return (
      <AppContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn,
          user,
          setUser,
          token,
          dateTime,
          setDateTime,
          role,
          userRole,
          setuserRole,
          isScaled,
        }}
      >
        {children}
      </AppContext.Provider>
    );
};
export default function useApp() {
  return useContext(AppContext);
}
