import logo from "assets/logo.svg";
import { useEffect, useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import UserMenu from "./UserMenu";
import DateTime from "./DateTime";
import Info from "./Info";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function Header() {
  const [showSideMenu, setShowMenu] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const { user, isScaled } = useApp();

  useEffect(() => {
    if (user && user.role.filter((role: any) => role === "admin")) {
      setisAdmin(true);
    }
  }, [user]);

  return (
    <div className="w-full bg-dark relative z-40">
      <div
        className={classNames(
          isScaled ? "h-20" : "h-16",
          "mx-auto max-w-screen-3xl flex items-center px-3"
        )}
      >
        <div className="relative z-50 flex items-center gap-5">
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300"
          >
            {!showSideMenu && <FiMenu size="30" />}
            {showSideMenu && <FiArrowLeft size="30" />}
          </div>
          <Link to="/">
            <img
              src={logo}
              className={classNames(isScaled ? "h-12" : "h-8")}
              alt="logo"
            />
          </Link>
          {/* <div className="text-2xl">Cultural Hub</div> */}
        </div>
        <div className="ml-36">
          {isAdmin && (
            <div className="rounded bg-gradient-to-r from-green to-blue h-11 w-full items-center justify-center">
              <Link to="/users">
                <button
                  // onClick={() => window.open("/users","_self")}
                  className="text-center bg-gradient-to-r from-gray to-black h-100 rounded py-2 w-36  hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5 "
                >
                  Users
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="flex-1" />
        <DateTime />
        <div className="mx-2">
          <Info />
        </div>
        <UserMenu />
        <SideMenu
          closeMenu={() => setShowMenu(false)}
          showSideMenu={showSideMenu}
        />
      </div>
    </div>
  );
}
